import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import LayoutBlogList from "../components/layoutBlogList"
import BlockContent from "@sanity/block-content-to-react"
import { Pagination } from "./BlogStyles"

export default class BlogListThirteenTwelve extends React.Component {
  render() {
    const serializers = {
      types: {
        code: props => (
          <pre data-language={props.node.language}>
            <code>{props.node.code}</code>
          </pre>
        ),
      },
    }
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <>
        <LayoutBlogList>
          <h2>
            {this.props.location.pathname.split("/")[1] ===
              "TwentyThirteenPosts" && <>2013-2014 Archives</>}
          </h2>
          {this.props.data.allSanityTwentyThirteenPost.edges.map(
            ({ node: post }) => (
              <div className="blog-single" key={post.id}>
                {post.postImage && post.postImage.asset ? (
                  <Img fluid={post.postImage.asset.fluid} />
                ) : null}
                <p>{post.postDate}</p>
                <h3>{post.postTitle}</h3>
                <BlockContent
                  blocks={post._rawPostContent}
                  serializers={serializers}
                />
              </div>
            )
          )}
        </LayoutBlogList>
        <Pagination>
          {!isFirst && (
            <Link to={`/TwentyThirteenPosts/${prevPage}`} rel="prev">
              ← Previous Page
            </Link>
          )}
          {Array.from({ length: numPages }, (_, i) => (
            <Link
              key={`pagination-number${i + 1}`}
              to={`/TwentyThirteenPosts/${i === 0 ? "" : i + 1}`}
            >
              {i + 1}
            </Link>
          ))}
          {!isLast && (
            <Link to={`/TwentyThirteenPosts/${nextPage}`} rel="next">
              Next Page →
            </Link>
          )}
        </Pagination>
      </>
    )
  }
}

export const blogListTwentyThirteenQuery = graphql`
  query blogListTwentyThirteenQuery($skip: Int!, $limit: Int!) {
    allSanityTwentyThirteenPost(
      sort: { fields: postDate, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          _rawPostContent(resolveReferences: { maxDepth: 10 })
          id
          postDate
          postTitle
          postImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`
